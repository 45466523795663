<template>
  <div class="public-view" v-if="!isLoading">
    <v-icon class="public-view__close" @click="goToFeed"> close </v-icon>
    <div @click="goToFeed" class="public-view__logo">
      <img
        src="./../../assets/spartan_logo_white.svg"
        alt="spartan-logo"
        class="spartan-logo"
      />
      <img
        src="./../../assets/spartan_tm.svg"
        alt="spartan"
        class="spartan-img"
      />
    </div>
    <div class="public-content">
      <span class="public-content__text public-content__title">
        {{ $t("invited_to_race_with_team") }}
      </span>
      <div class="public-items">
        <!-- join team section -->
        <div
          class="public-item"
          :class="{ 'public-item--active': currentStage === 'Team' }"
        >
          <div class="public-item__block">
            <img
              src="./../../assets/team-icon.svg"
              alt="team-icon"
              class="public-item__icon"
            />
            <div class="public-item__line" />
          </div>
          <div class="item-info">
            <span class="public-content__text">
              {{ $t("join") }} {{ teamInfo.name }}
            </span>
            <div v-if="currentStage === 'Team'" class="item-info__buttons">
              <base-button
                class="control-button"
                :btn-type="isJoining ? 'secondary-dark' : 'mem-primary'"
                :loading="isJoining"
                @click="join"
              >
                {{ $t("join") }}
              </base-button>
              <button
                type="button"
                class="mem-btn btn-tertiary-dark"
                @click="goToFeed"
              >
                {{ $t("cancel") }}
              </button>
            </div>
            <div v-else class="item-info__disabled">
              <span class="disabled__text">
                {{ $t("joined") }}
              </span>
              <img
                src="./../../assets/check-icon.svg"
                alt="check-icon"
                class="disabled__icon"
              />
            </div>
          </div>
        </div>
        <!-- buy ticket section -->
        <div
          class="public-item"
          :class="{ 'public-item--active': currentStage === 'Ticket' }"
        >
          <div class="public-item__block">
            <img
              src="./../../assets/ticket-icon.svg"
              alt="ticket-icon"
              class="public-item__icon"
            />
          </div>
          <div class="item-info">
            <span class="public-content__text">
              {{ $t("buy_ticket_to") }} {{ eventInfo.name }}
            </span>
            <div v-if="currentStage !== 'Finish'" class="item-info__buttons">
              <base-button @click="goToTicket" class="control-button">
                {{ $t("buy") }}
              </base-button>
              <button
                type="button"
                class="mem-btn btn-tertiary-dark"
                @click="goToFeed"
              >
                {{ $t("later") }}
              </button>
            </div>
            <div v-if="currentStage === 'Finish'" class="item-info__disabled">
              <span class="disabled__text">
                {{ $t("committed") }}
              </span>
              <img
                src="./../../assets/check-icon.svg"
                alt="check-icon"
                class="disabled__icon"
              />
            </div>
          </div>
        </div>
        <!-- finish section -->
        <div
          class="public-item public-item__add-team"
          :class="{ 'public-item--active': currentStage === 'Finish' }"
        >
          <div class="public-item__block">
            <img
              src="./../../assets/add-team-icon.svg"
              alt="add-team-icon"
              class="public-item__icon"
            />
          </div>
          <div class="item-info">
            <span class="public-content__text">
              {{ $t("invite_to_race_with_team_desc") }}
            </span>
            <div class="item-info__buttons">
              <base-button class="control-button" @click="goToEvent">
                {{ $t("view_ticket") }}
              </base-button>
            </div>
          </div>
        </div>
        <div
          class="public-item__line-two"
          :class="{
            'public-item__line-two--active': currentStage !== 'Team',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";
import { mapActions, mapGetters } from "vuex";
import { isEmpty } from "lodash";

export default {
  name: "InviteTeamToEvent",
  components: { BaseButton },
  data: () => ({
    isLoading: true,
    isJoining: false,
    // Possible stages: Team, Ticket, Finish
    stage: "Team",
  }),
  computed: {
    ...mapGetters(["inviteTeamInfo"]),
    routeParams() {
      return {
        eventId: +this.$route.query?.event_id,
        teamId: +this.$route.query?.team_id,
        teamInviteCode: this.$route.query?.invite_code || "",
        stage: this.$route.query?.stage,
        ticketTypeId: this.$route.query.ticket_type_id,
      };
    },
    teamInfo() {
      if (this.inviteTeamInfo?.team) return this.inviteTeamInfo.team;
      return {};
    },
    eventInfo() {
      if (this.inviteTeamInfo?.event) return this.inviteTeamInfo.event;
      return {};
    },
    currentStage() {
      // if (this.routeParams.stage) return this.routeParams.stage;
      // return this.stage;
      if (this.stage === "Finish" || this.routeParams.stage === "Finish")
        return "Finish";
      if (this.stage === "Ticket") return "Ticket";
      return "Team";
    },
  },
  methods: {
    ...mapActions(["getInviteTeamInfo", "joinTeam"]),
    goToFeed() {
      this.$router.push({ path: "/home" });
    },
    async join() {
      this.isJoining = true;
      try {
        await this.joinTeam({
          teamId: this.teamInfo.id,
          inviteCode: this.routeParams.teamInviteCode,
        });
      } catch (err) {
        console.log("Join team error. Moving to the next step...", err);
      }
      this.isJoining = false;
      if (this.inviteTeamInfo.exist_ticket) this.stage = "Finish";
      else this.stage = "Ticket";
    },
    goToTicket() {
      this.stage = "Finish";
      let query = {
        goToCheckout: "1",
        [`cartTasks[ticketTypeId_${this.routeParams.ticketTypeId}][typeId]`]: `${this.routeParams.ticketTypeId}`,
        [`cartTasks[ticketTypeId_${this.routeParams.ticketTypeId}][eventId]`]: `${this.eventInfo.ts_id}`,
        [`cartTasks[ticketTypeId_${this.routeParams.ticketTypeId}][quantity]`]: `1`,
        eventId: this.routeParams.eventId,
        teamId: this.routeParams.teamId,
      };
      this.$router.push({
        name: "checkout",
        params: { region: "us" },
        query,
      });
    },
    goToEvent() {
      this.$router.push({
        name: "eventPage",
        params: { id: this.eventInfo.id },
      });
    },
    setViewState() {
      if (isEmpty(this.teamInfo) && isEmpty(this.eventInfo)) return;

      if (this.teamInfo.joined && this.inviteTeamInfo.exist_ticket)
        this.stage = "Finish";
      if (this.teamInfo.joined && !this.inviteTeamInfo.exist_ticket)
        this.stage = "Ticket";
    },
  },
  async mounted() {
    let result = await this.getInviteTeamInfo({
      event_id: this.routeParams.eventId,
      team_id: this.routeParams.teamId,
    });
    if (result) console.log(result);

    this.setViewState();

    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.public-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;

  .public-view__close {
    position: absolute;
    top: 21px;
    right: 21px;
    color: #ffffff;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }

  .public-view__logo {
    cursor: pointer;
    display: flex;
    padding-top: 40px;

    .spartan-logo {
      padding-right: 3px;
    }
  }

  .public-content {
    padding-top: 68px;
    padding-bottom: 120px;
    max-width: 527px;
    width: 100%;

    .public-content__text {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
    }

    .public-items {
      position: relative;
      padding-top: 12px;

      .public-item {
        display: flex;
        margin-top: 10px;
        background: #222222;
        opacity: 0.3;
        padding: 42px 101px 42px 44px;
        height: 206px;

        .public-item__block {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 50px;

          .public-item__icon {
            max-height: 34px;
            max-width: 50px;
          }

          .public-item__line {
            position: absolute;
            top: 127px;
            left: 66px;
            border: 1px solid #ffffff;
            height: 130px;
          }
        }

        .item-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 38px;

          .item-info__buttons {
            display: flex;
            pointer-events: none;

            .control-button {
              margin-right: 12px;
              padding: 0 37px;
            }
          }

          .item-info__disabled {
            display: flex;
            align-items: center;

            .disabled__text {
              text-transform: uppercase;
              font-size: 14px;
              font-weight: 700;
              padding-right: 9px;
            }

            .disabled__icon {
              width: 18px;
              height: 14px;
            }
          }
        }

        &--active {
          opacity: 1;

          .item-info {
            .item-info__buttons {
              pointer-events: auto;
            }
          }
        }
      }

      .public-item__line-two {
        position: absolute;
        bottom: 185px;
        left: 66px;
        border: 1px solid #ffffff;
        height: 130px;
        opacity: 0.3;

        &--active {
          opacity: 1;
        }
      }

      .public-item__add-team {
        padding: 42px 101px 24px 44px;

        .public-item__block {
          padding-right: 3px;
        }

        .item-info {
          .item-info__buttons {
            .control-button {
              padding: 0 26px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .public-view {
    .public-view__close {
      top: 29px;
      right: 24px;
    }

    .public-view__logo {
      padding-top: 25px;

      .spartan-logo {
        width: 22px;
        height: 22px;
      }

      .spartan-img {
        max-width: 91px;
      }
    }

    .public-content {
      padding-top: 28px;
      padding-bottom: 40px;

      .public-content__text {
        font-size: 16px;
      }

      .public-content__title {
        display: flex;
        padding: 0 135px 0 20px;
        font-size: 18px;
      }

      .public-items {
        .public-item {
          height: 170px;
          padding: 24px;

          .public-item__block {
            width: 35px;

            .public-item__icon {
              max-height: 25px;
              max-width: 35px;
            }

            .public-item__line {
              top: 85px;
              left: 47px;
            }
          }

          .item-info {
            margin-left: 26px;

            .item-info__buttons {
              .control-button {
                height: 36px;
              }

              .btn-tertiary-dark {
                height: 36px;
              }
            }
          }
        }

        .public-item__line-two {
          bottom: 158px;
          left: 47px;
        }
      }
    }
  }
}
</style>
